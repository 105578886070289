import buyer_dom_manage_repairDetail from '@/lib/data-service/flight/buyer_dom_manage_repairDetail';
import RefundChangeLabel from '@/page/distributor/air-ticket/admin/component/refund-change-label/index.vue';
export default {
    data() {
        return {
            loading: false,
            repOrderNo: '',
            detail: {},
        }
    },
    components: {RefundChangeLabel},
    created() {
    },
    mounted() {
    },
    activated() {
        this.repOrderNo = this.$route.query.repOrderNo;
        this.getDetail();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        getDetail() {
            this.loading = true;
            buyer_dom_manage_repairDetail({repOrderNo: this.repOrderNo}).then(res => {
                this.detail = res.result;
            }).finally(() => {
                this.loading = false;
            })
        },
        back() {
            this.$router.go(-1)
        },
    }
}
